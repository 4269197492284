var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{class:{
    nav: true,
    'nav--collapsable': _vm.collapsable,
    'nav--mobile': _vm.layout.breakpoint == 1,
    'nav--hidden': _vm.collapsable ? !_vm.opened : false,
  },attrs:{"id":"module-nav"}},[(!_vm.loading.course)?_c('div',{staticClass:"nav__content"},[_c('div',{staticClass:"nav__header"},[_c('span',{staticClass:"nav__header__title"},[_vm._v(_vm._s(_vm.course.title))]),_c('div',{staticClass:"nav__header__progress"},[_c('span',{staticClass:"nav__header__progress__label"},[_vm._v(" "+_vm._s(_vm.$t("my_courses.progress"))+" "+_vm._s(_vm.coursePercentage)+"%")]),_c('IconSax',{attrs:{"name":"medal-star","primary":"","type":_vm.courseIsCompleted ? 'bold' : 'outline'}})],1),_c('ProgressBar',{attrs:{"percentage":_vm.coursePercentage ? _vm.coursePercentage : 0}})],1),_vm._l((_vm.modules),function(module){return _c('AccordionPlay',{key:module.id,staticClass:"module",attrs:{"id":`module-${module.id}`,"title":module.title,"type":!module.is_liberated ? 'lesson' : '',"defaultExpanded":module.id == _vm.getCurrentModuleOpened && _vm.openedLesson,"lock":module.is_liberated === false},on:{"toggle":function($event){module.is_liberated ? _vm.openLesson({ ...module }) : _vm.denyCourse(module)}}},[(_vm.loadingModule && _vm.loading.module)?_c('ul',{staticClass:"nav__class__list"},[_c('li',{staticClass:"spinner"},[_c('b-spinner',{attrs:{"label":"Loading..."}})],1)]):_c('ul',{staticClass:"nav__class__list"},_vm._l((_vm.currentModuleLessons),function(lesson){return _c('li',{key:lesson.id,class:{
            nav__class__link: true,
            'nav__class__link--completed':
              lesson.is_liberated && lesson.is_completed,
            'nav__class__link--blocked': lesson.is_liberated === false,
            'nav__class__link--selected':
              lesson.id == _vm.$route.params.lessonId ||
              lesson.id == _vm.currentLesson.id,
          },on:{"click":function($event){lesson.is_liberated
              ? _vm.gotoLesson({
                  courseId: lesson.course_id,
                  moduleId: lesson.module_id,
                  lessonId: lesson.id,
                  type: 2,
                })
              : _vm.denyCourse(lesson)}}},[_c('IconSax',{attrs:{"name":_vm.getLessonIcon(lesson),"type":"bold"}}),_c('span',{staticClass:"nav__class__link__label"},[_vm._v(_vm._s(lesson.title))])],1)}),0)])})],2):_vm._e(),(_vm.opened)?_c('div',{class:{
      nav__overlay: true,
      'nav__overlay--mobile': _vm.layout.breakpoint == 1,
    },on:{"click":function($event){return _vm.$emit('close')}}}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }